import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { InlineCode } from '../../components/inline-code/inline-code';
import { ItalicText } from '../../components/italic-text/italic-text';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Barrierefreiheit ist eine Entscheidung. Designer:innen können Farben mit ausreichendem Kontrast verwenden oder
Farben aus rein ästhetischen Gründen wählen. Web-Entwickler:innen können eine Schaltfläche mit dem
nativen `}<InlineCode mdxType="InlineCode">{`button`}</InlineCode>{`-Tag implementieren oder stattdessen das
generische `}<InlineCode mdxType="InlineCode">{`div`}</InlineCode>{`-Tag verwenden.`}</p>
    <p>{`Idealerweise wird Barrierefreiheit von Anfang an berücksichtigt. In den Anforderungen sind die Bedürfnisse von Menschen
mit Behinderungen klar formuliert. Designer:innen erstellen Mockups, die diese Anforderungen erfüllen. Die Entwickler:innen
implementieren die Funktionen mit semantischem Markup. Tester:innen überprüfen, ob die Webinhalte die Anforderungen
erfüllen.`}</p>
    <p>{`In der Realität wird Barrierefreiheit oft zu spät berücksichtigt. Dies kann besonders problematisch sein, wenn es um
`}<a parentName="p" {...{
        "href": "https://medium.com/sketch-app-sources/why-should-you-build-a-ui-component-library-854656b91a96"
      }}>{`UI-Komponenten-Bibliotheken`}</a>{`
geht. Ich zeige euch, wie ihr die Barrierefreiheit der Bibliothek prüft, bevor ihr die erste Codezeile schreibt. Und was
ihr bei der Arbeit mit UI-Komponenten beachten solltet.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQCAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAABAP/aAAwDAQACEAMQAAABzL3YkkNg/wD/xAAZEAADAQEBAAAAAAAAAAAAAAABAgMAERP/2gAIAQEAAQUCFGz8ZQoInJKbxQlYzO//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAbEAACAgMBAAAAAAAAAAAAAAAAAQIxEVGRof/aAAgBAQAGPwJa0KOMFobcSvSn0//EABwQAAIDAQADAAAAAAAAAAAAAAABESExcUGB0f/aAAgBAQABPyGhrgOgLJzTpiZd6GyhRkdfw88WYE7nYf/aAAwDAQACAAMAAAAQny//xAAWEQEBAQAAAAAAAAAAAAAAAAAAESH/2gAIAQMBAT8Q1H//xAAWEQEBAQAAAAAAAAAAAAAAAAAAIYH/2gAIAQIBAT8QjX//xAAdEAEBAAMAAgMAAAAAAAAAAAABEQAhMUFRYXHB/9oACAEBAAE/ECMSpoITyudUxLp5K8xM1ybifGu/eQI+ioUPeA6yiFNbebx1oI63x/c//9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Ein Spielzeug wird aus dutzenden Lego-Bausteinen zusammengesetzt.",
          "title": "Ein Spielzeug wird aus dutzenden Lego-Bausteinen zusammengesetzt.",
          "src": "/static/42cb64cd5660164f84b0a37077afae7c/e5166/pexels-alena-darmel-lego-bricks.jpg",
          "srcSet": ["/static/42cb64cd5660164f84b0a37077afae7c/f93b5/pexels-alena-darmel-lego-bricks.jpg 300w", "/static/42cb64cd5660164f84b0a37077afae7c/b4294/pexels-alena-darmel-lego-bricks.jpg 600w", "/static/42cb64cd5660164f84b0a37077afae7c/e5166/pexels-alena-darmel-lego-bricks.jpg 1200w", "/static/42cb64cd5660164f84b0a37077afae7c/b17f8/pexels-alena-darmel-lego-bricks.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Foto: © Alena Darmel / pexels.com`}</em></p>
    <h2>{`Die Vorteile von UI-Bibliotheken`}</h2>
    <p>{`HTML-Elemente sind die Grundbausteine einer jeden Website. Sie decken aber nicht alle Arten von Inhalten und möglichen
Anwendungsfällen ab. Komplexe UI-Muster wie `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/ARIA/apg/patterns/accordion/"
      }}>{`Akkordeons`}</a>{`,
`}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/ARIA/apg/patterns/carousel/"
      }}>{`Karussells`}</a>{` oder `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/ARIA/apg/patterns/tabs/"
      }}>{`Tabs`}</a>{`
erfordern ohnehin individuelle Implementierungen.`}</p>
    <p>{`Um die Entwicklung zu beschleunigen und ein einheitliches Erscheinungsbild zu gewährleisten, verwendet eine moderne
Webanwendung meist eine UI-Komponenten-Bibliothek. Diese besteht aus einer Reihe von Design-Elementen, die ihr in eurer
Anwendung verwenden könnt. Meistens könnt ihr auch ein Farbthema anwenden und die Funktionalität der Komponenten anpassen.`}</p>
    <p>{`Es gibt hunderte verschiedene Bibliotheken für die großen Web-Frameworks wie `}<a parentName="p" {...{
        "href": "https://angular.io/"
      }}>{`Angular`}</a>{`,
`}<a parentName="p" {...{
        "href": "https://react.dev/"
      }}>{`React`}</a>{` oder `}<a parentName="p" {...{
        "href": "https://vuejs.org/"
      }}>{`Vue.js`}</a>{`. Zum Beispiel, wenn euch das Material-Designsystem gefällt,
könnt ihr `}<a parentName="p" {...{
        "href": "https://material.angular.io/"
      }}>{`Angular Material`}</a>{`, `}<a parentName="p" {...{
        "href": "https://mui.com/"
      }}>{`Material UI`}</a>{` oder
`}<a parentName="p" {...{
        "href": "https://matsp.github.io/material-components-vue/"
      }}>{`Material Components Vue`}</a>{` verwenden.`}</p>
    <h2>{`Die Barrierefreiheit der Bibliothek prüfen`}</h2>
    <p>{`Hier ist eine Checkliste für die Auswahl einer barrierefreien UI-Komponenten-Bibliothek:`}</p>
    <ol>
      <li parentName="ol">{`Wird Barrierefreiheit auf der Website oder in der Dokumentation der Bibliothek erwähnt? Auf der Homepage von Angular
Material heißt es zum Beispiel: `}<ItalicText mdxType="ItalicText">{`„Internationalisierte und barrierefreie Komponenten für alle.“`}</ItalicText></li>
      <li parentName="ol">{`Wird in der Dokumentation erklärt, ob und wie die Komponenten barrierefrei implementiert wurden? Die Dokumentation von Angular
Material enthält `}<a parentName="li" {...{
          "href": "https://material.angular.io/components/tabs/overview#accessibility"
        }}>{`eigene Abschnitte zur Barrierefreiheit`}</a>{`
für jede Komponente.`}</li>
      <li parentName="ol">{`Die Dokumentation enthält in der Regel Demoseiten und Beispiele für die verschiedenen Komponenten. Überprüft das Markup und
die `}<a parentName="li" {...{
          "href": "https://www.w3.org/WAI/WCAG21/Understanding/name-role-value.html"
        }}>{`Barrierefreiheits-Eigenschaften`}</a>{` mithilfe der Entwicklertools
des Browsers. Testet mit assistiven Technologien wie der Tastatur und verschiedenen Desktop- und mobilen Screenreadern.`}</li>
    </ol>
    <h2>{`Keine Garantie für komplette Barrierefreiheit!`}</h2>
    <p>{`Die Auswahl einer barrierefreien UI-Komponenten-Bibliothek ist ein wichtiger erster Schritt, um eure Web-Applikation
barrierefrei zu gestalten. Aber sie ist keine Garantie dafür. Jedes Mal, wenn ihr eine Komponente auf einer Seite verwendet,
solltet ihr die folgenden Fragen beantworten:`}</p>
    <ul>
      <li parentName="ul">{`Wo und wie wird die Komponente verwendet? Denkt über den Kontext und die Reihenfolge der Inhalte nach.`}</li>
      <li parentName="ul">{`Steht die Art der Verwendung im Einklang mit dem eigentlichen Zweck der Komponente? Oder wollt ihr nur ein bestimmtes
Erscheinungsbild erreichen?`}</li>
    </ul>
    <p>{`Die visuelle Darstellung des Inhalts sollte nicht bestimmen, welche Komponente ihr verwendet. Es ist einfach, ein bestimmtes
Design mit CSS anzuwenden. Aber es ist fast unmöglich oder zumindest sehr mühsam, das semantische Markup einer Komponente und
die implementierte Tastaturinteraktion zu überschreiben.`}</p>
    <p>{`Hier ein Beispiel: Die Komponente `}<InlineCode mdxType="InlineCode">{`<`}{`mat-chip-listbox`}{`>`}</InlineCode>{` von Angular Material bietet eine
Mehrfach-Auswahlliste, die das `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/ARIA/apg/patterns/listbox/"
      }}>{`Listbox-Pattern`}</a>{` anwendet. Das semantische
Markup vermittelt den assistiven Technologien, dass das Element mehrere Optionen bietet, von denen eine oder mehrere ausgewählt
werden können. Die gesamte Auswahlliste erhält den Tastaturfokus über die `}<InlineCode mdxType="InlineCode">{`TAB`}</InlineCode>{`-Taste. Mit den Pfeiltasten
wird der Fokus auf die nächste oder vorherige Option gesetzt.`}</p>
    <p>{`Stellt euch nun vor, euer Kunde sagt: `}<ItalicText mdxType="ItalicText">{`„Mir gefällt das Design der Chips-Komponente. Ich möchte, dass diese Auflistung
von Links genauso gestaltet wird!“`}</ItalicText>{` Glaubst du, dass diese Komponente wirklich für diesen Anwendungsfall geeignet ist?`}</p>
    <p>{`Es liegt in unserer Verantwortung als Web-Entwickler:innen, die jeweils passende Komponente auszuwählen. Und dem vermeintlich
einfachen Weg zu widerstehen, der zum Desaster werden würde! 😉`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      